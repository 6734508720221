<template>
  <b-col cols="12" lg="4" xl="4" xxl="4">
    <b-row
      class="d-none d-lg-none d-lg-flex"
      :style="{
        position: section ? 'relative' : 'fixed',
        height: section ? '100%' : 'calc(100vh - 100px)',
      }"
    >
      <!-- <b-col cols="12" class="ml-4 mt-2">
        <b-button
          v-if="!hide"
          class="back-btn"
          size="lg"
          @click="backButton"
          variant="link"
          style="min-width: inherit"
          ><img src="@/assets/images/small_back_arrow.svg"
        /></b-button>
      </b-col> -->
      <b-col cols="12" class="pl-5 ml-6">
        <b-row
          align-h="center"
          :style="{ height: section ? 'auto' : 'calc(100vh - 230px)' }"
        >
          <b-col cols="12" align-self="start">
            <h3 class="onboarding-title d-letter-spacing mt-6">{{ title }}</h3>
          </b-col>
          <b-col cols="12" align-self="end" class="mb-3">
            <template v-if="!section">
              <h6 class="step d-letter-spacing" v-if="step">
                <!-- Step {{ step }} of 4 -->
              </h6>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- mobile view -->
    <b-navbar fixed="top" class="d-lg-none sidenav" style="z-index: 498">
      <b-container fluid>
        <b-row align-v="center" style="width: 100%">
          <b-col cols="3">
            <b-button
              v-if="!hide"
              class="back-btn"
              size="lg"
              @click="backButton"
              variant="link"
              style="min-width: inherit"
              ><img src="@/assets/images/small_back_arrow.svg"
            /></b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar>
  </b-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    step: {
      type: String,
    },
    hide: {
      type: Boolean,
    },
    hideStep: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Boolean,
    },
  },
  methods: {
    backButton(event) {
      this.$emit("back", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  @include media-breakpoint-up(xxl) {
    img {
      width: 25px * $scale-font;
      height: 13px * $scale-font;
    }
  }
}
</style>
